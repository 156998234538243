<template xmlns="http://www.w3.org/1999/html">
  <div class="uk-grid-medium" uk-grid>


  </div>
</template>

<script>
import store from "@/core/services";

import assignment_module, {
  BASE_URL as ASSIGNMENT_BASE_URL,
  ERROR as ASSIGNMENT_ERROR,
  GET_ITEM,
  MODULE_NAME as ASSIGNMENT_MODULE_NAME, UPDATE_ITEM,
} from "@/core/services/store/assignment.module";

const _ASSIGNMENT_MODULE = ASSIGNMENT_MODULE_NAME;
export default {
  name: "ClassRoomEducation",
  components: {},
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_ASSIGNMENT_MODULE, assignment_module);
  },
  data() {
    return {};
  },
  computed: {
    error: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    callClassRoomEducationEndpoint() {
      store
        .dispatch(ASSIGNMENT_MODULE_NAME + "/" + UPDATE_ITEM, {
          url: `${ASSIGNMENT_BASE_URL}s/${this.$route.params.assignment}/classroom-education`,
        })
        .then((result) => {
          this.$router.push({ name: 'user.assignment-detail', params: { id: this.$route.params.assignment } })
        });
    },
  },
  mounted() {
    let url = window.location.href;
    if (url.includes("classroom-education")) {
      window.localStorage.setItem('redirect_url', url)
    }
    this.callClassRoomEducationEndpoint();
  },
  watch: {},
};
</script>
<style scoped></style>
